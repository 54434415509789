import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes,  useLocation  } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";

import SEO from "./components/SEO";
import NavigationBar from "./components/Navbar";
import Footer from "./components/Footer";

import Accueil from "./pages/Accueil";
import TarifsCreation from "./pages/TarifsCreation";
import TarifsRefonte from "./pages/TarifsRefonte";
import Galerie from "./pages/Galerie";
import Contact from "./pages/Contact";
import MentionsLegales from "./pages/MentionsLegales";

// import Login from "./components/LoginFirebase"
import ContentManager from "./components/ContentManager"

import GoogleAnalytics from "./components/GoogleAnalytics"; // Import du composant

function App() {
  const trackingId='G-62L2B4P0P4'; // Remplace par ton ID Google Analytics

  return (
    <Router>
      <ScrollToTop />
      <SEO />
      <GoogleAnalytics trackingId={trackingId} />
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/tarifs/creation-de-site" element={<TarifsCreation />} />
        <Route path="/tarifs/refonte-de-site" element={<TarifsRefonte />} />
        <Route path="/galerie" element={<Galerie />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/content-manager" element={<ContentManager />} />
        {/* Ajoute d'autres routes ici */}
      </Routes>
      <Footer/>
      </Router>
    
  );
}

export default App;
