import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Button, Nav, Card, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Login from "../components/LoginFirebase"; // Assure-toi que le chemin est correct pour ton projet
import "../styles/Accueil.css";

function Accueil() {
  const [clickCount, setClickCount] = useState(0); // État pour compter les clics
  const [showLoginModal, setShowLoginModal] = useState(false); // État pour afficher le modal de login

  // Fonction de gestion des clics sur le h1
  const handleAlesClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  // Afficher le modal après 3 clics
  useEffect(() => {
    if (clickCount === 5) {
      setShowLoginModal(true); // Affiche le modal de login
    }
  }, [clickCount]);

  // Fonction pour fermer le modal
  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
    setClickCount(0);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container fluid className="text-center mt-5">

        {/* Modal de login */}
        <Modal show={showLoginModal} onHide={handleCloseLoginModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login closeModal={handleCloseLoginModal} />
        </Modal.Body>
      </Modal>

      <Row className="bg-image d-flex align-items-center justify-content-center color-warning">
        <Col>
        <h1>
            Création et Refonte de Sites Internet à{" "}
            <span
              onClick={handleAlesClick}
              style={{
                color: "inherit",  // Garde la couleur héritée du parent
                fontFamily: "inherit",  // Garde la police héritée du parent
                fontSize: "inherit",  // Garde la taille de police héritée du parent
                fontWeight: "inherit",  // Garde le poids de la police héritée du parent
              }}
            >
              Alès
            </span>
          </h1>
          <h2>Création de sites vitrines, et services associés.</h2>
          <h3>
            J'offre des services de design Web, de développement, d'hébergement
            et d'optimisation SEO pour garantir le succès de votre présence en
            ligne.
          </h3>
        </Col>
      </Row>

      {/************************************************* Article avec titre, texte et image ******************************************/}
      <Row className="m-5 container-lg mx-auto d-flex align-items-center">
        <Col md={6} className="text-start">
          <h2 className="mb-4">À propos</h2>
          <p>
            "Diplômé de l'académie WebForce3 Marseille, développeur web
            passionné et spécialisé en référencement naturel, je créer pour
            votre entreprise une présence en ligne sur mesure et hautement
            performante.
            <br />
            Grâce à mes compétences en conception de sites web et en
            optimisation SEO, je transforme vos idées en plateformes digitales
            attractives qui génèrent du trafic qualifié et fidélisent votre
            clientèle.
            <br />
            Je suis convaincu que chaque entreprise est unique et mérite une
            solution personnalisée. C'est pourquoi je m'engage à analyser vos
            besoins, à concevoir un site web adapté à votre image de marque et à
            vous accompagner tout au long de votre projet."
          </p>
        </Col>

        {/* Colonne pour l'image à droite */}
        <Col md={6}>
          <img
            src="assets/images/profil.jpg"
            alt="Marc SKALSKI créateur de site internet"
            className="img-fluid rounded-pill"
            width="250"
            height="250"
          />
        </Col>
      </Row>

      {/************************************************* Pourquoi chosir un freelance? ******************************************/}

      <Row className="mt-5 d-flex justify-content-center">
        <h2 className="text-center mt-5 mb-5 warning-underline">
          POURQUOI CHOISIR UN FREELANCE?
        </h2>
        {/* Première ligne de cartes */}
        <Col md={4} className="mb-4 d-flex">
          <Card className="border-0 h-100 w-100 d-flex flex-column styled-card1">
            <Card.Body>
              <Card.Title as="h3">Tarifs plus compétitifs</Card.Title>
              <Card.Text>
                Les freelances ont généralement des frais généraux plus bas que
                les sociétés, ce qui leur permet d'offrir des tarifs plus
                compétitifs. Cela peut être particulièrement avantageux pour les
                petites entreprises ou les startups avec des budgets limités.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4 d-flex">
          <Card className="border-0 h-100 w-100 d-flex flex-column styled-card2">
            <Card.Body>
              <Card.Title as="h3">Passion et motivation</Card.Title>
              <Card.Text>
                Les freelances sont très passionnés par leur domaine d'expertise
                et investissent personnellement dans chaque projet, ce qui se
                traduit par un travail de haute qualité.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card className="border-0 h-100 w-100 d-flex flex-column styled-card3">
            <Card.Body>
              <Card.Title as="h3">Communication directe</Card.Title>
              <Card.Text>
                Travailler avec un freelance permet une communication plus
                directe et simplifiée. Vous parlez directement avec la personne
                qui réalise le travail, ce qui réduit les malentendus et
                améliorer l'efficacité.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className=" d-flex justify-content-center">
        {/* Deuxième ligne de cartes */}
        <Col md={3} className="mb-4">
          <Card className="border-0 h-100 w-100 d-flex flex-column styled-card4">
            <Card.Body>
              <Card.Title as="h3">Relation à long terme</Card.Title>
              <Card.Text>
                Travailler avec un freelance permet de développer une
                relation de confiance à long terme. Si vous êtes satisfait de
                son travail, vous pouvez continuer à collaborer avec cette
                personne sur de futurs projets, ce qui peut créer une synergie
                bénéfique.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} className="mb-4">
          <Card className="border-0 h-100 w-100 d-flex flex-column styled-card5">
            <Card.Body>
              <Card.Title as="h3">Personnalisation du service</Card.Title>
              <Card.Text>
                Les freelances offrent des services plus personnalisés,
                ajustés précisément à vos besoins. Ils ont la possibilité de se
                concentrer entièrement sur votre projet, sans être distraits par
                d'autres projets en cours comme cela pourrait être le cas dans
                une société.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} className="mb-4">
          <Card className="border-0 h-100 w-100 d-flex styled-card6">
            <Card.Body>
              <Card.Title as="h3">
                Adaptation aux nouvelles technologies
              </Card.Title>
              <Card.Text>
                Les freelances sont à l'avant-garde en matière de technologies
                et de tendances. Ils sont plus enclins à se former régulièrement
                et à adopter de nouvelles techniques, car cela leur permet de
                rester compétitifs sur le marché.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/************************************************* Services ******************************************/}
      <Row className="m-5 fs-5 d-flex align-items-center">
        <h2 className="mt-5 mb-5 warning-underline">MES SERVICES</h2>
        <Col md={6} data-aos="fade-right">
          <img
            src="assets/images/creation.svg"
            alt="Création de site vitrine sur mesure à Alès"
            className="img-fluid mon-image"
          />
        </Col>

        <Col md={6} className="text-start" data-aos="zoom-in">
          <h2 className="mb-5 mt-5">Création de site vitrine</h2>
          <h3>Besoin d'un site internet pour votre société?</h3>
          <p>
            Présentez votre entreprise, vos produits ou vos services de manière
            élégante et professionnelle grâce à un site vitrine sur mesure.
            <br />
            <br />
            Je conçois des sites clairs et attrayants, mettant en valeur votre
            image de marque tout en offrant une navigation fluide et intuitive.
            <br />
            <br />
            Chaque site vitrine est conçu de manière responsive, garantissant
            une expérience utilisateur optimale sur tous les appareils, qu’il
            s’agisse de smartphones, tablettes ou ordinateurs.
            <br />
            <br />
            Mettez en avant votre entreprise avec un site vitrine moderne et
            captivant !
          </p>

          <Nav.Link as={Link} to="/tarifs/creation-de-site">
            <Button variant="warning" className="btn-hover-zoom fs-4">
              TARIFS
            </Button>
          </Nav.Link>
        </Col>
      </Row>
      <Row className="m-5 fs-5  d-flex align-items-center">
        <Col md={6} className="text-start " data-aos="zoom-in">
          <h2 className="mb-5">Refonte de site web</h2>
          <h3>Votre site internet se fait vieux?</h3>
          <p>
            Modernisez votre site existant pour le rendre plus attractif,
            fonctionnel et adapté aux dernières tendances du web.
            <br />
            <br />
            Je transforme votre site en une plateforme moderne et engageante,
            optimisée pour offrir une expérience utilisateur supérieure.
            <br />
            <br /> De plus, j'intègre les meilleures pratiques en matière de SEO
            pour améliorer votre visibilité sur les moteurs de recherche,
            attirer plus de visiteurs et augmenter vos conversions.
            <br />
            <br /> Donnez un coup de jeune à votre site avec une refonte
            complète et boostez votre présence en ligne !
          </p>

          <Nav.Link as={Link} to="/tarifs/refonte-de-site" className="me-4">
            <Button variant="warning" className="btn-hover-zoom fs-4">
              TARIFS
            </Button>
          </Nav.Link>
        </Col>
        <Col md={6} data-aos="fade-up">
          <img
            src="assets/images/refonte.svg"
            alt="Refonte de site web à Alès"
            className="img-fluid mon-image"
          />
        </Col>
      </Row>

    </Container>
  );
}

export default Accueil;
