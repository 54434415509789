import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import DevisModalRefonte from "../components/DevisModalRefonte";
import "../styles/Creation-Refonte.css";
import AOS from "aos";
import "aos/dist/aos.css";

function TarifsRefonte() {
  const { hash } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const handleClose = () => setShowModal(false);

  // Fonction pour configurer et ouvrir le modal
  const handleShowModal = (title, suggestions) => {
    setModalProps({ title, suggestions }); // Passer le titre et les suggestions
    setShowModal(true); // Afficher le modal
  };

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container className="my-5">
      <Row>
        <h1 className="text-center mt-5">Refonte de Site</h1>
        <Col md={6} className="my-5 border-end">
          <h3 className="mb-5">Essentiel Refonte</h3>
          <p>
            À partir de <strong>600 € HT</strong>
          </p>
          <p>
            Idéal pour les entreprises ou startups qui souhaitent moderniser
            leur site existant sans complications.
          </p>
          <Button
            variant="warning"
            className="w-100 mb-4 fw-bold"
            onClick={() =>
              handleShowModal(
                "Vous êtes intéressé(e) par la formule Essentiel.",
                [
                  "Optimisation SEO avancée",
                  "Système de gestion de contenu (CMS)",
                  "Formulaires interactifs (enquête, demande de devis)",
                  "Module d'analyse de trafic (Google Analytics)",
                  "UX/UI design optimisé pour maximiser la conversion des visiteurs en clients",
                ]
              )
            }
          >
            COMMENCER
          </Button>
          <p>
            <strong>Design et conception :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Mise à jour
            graphique basée sur un modèle préconçu avec une personnalisation.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Charte
            graphique simplifiée avec ajustement des couleurs actuelles.
          </p>
          <p>
            <strong>Fonctionnalités :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Optimisation
            SEO de base (titres, descriptions, URL propres).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Mise à jour
            du formulaire de contact (envoi par email).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Liens directs
            avec les réseaux sociaux .
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Adaptation du
            site existant pour qu’il soit 100 % responsive (compatibilité mobile
            et tablette).
          </p>
          <p>
            <strong>Maintenance :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Support
            technique pour corrections de bugs (1 mois).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Hébergement
            et nom de domaine à votre charge, environ 4.20 € HT/Mois.
          </p>
        </Col>

         {/********************************************************** PREMIUM ************************************************************/}

        <Col md={6} className="my-5">
          <h3 className="mb-5">Premium Refonte</h3>
          <p>
            A partir de <strong>1200 € HT</strong>{" "}
          </p>
          <p>
            Pour les entreprises souhaitant améliorer leur site avec des
            fonctionnalités supplémentaires et un design plus moderne.
          </p>
          <Button
            variant="warning"
            className="w-100 mb-4 fw-bold"
            onClick={() =>
              handleShowModal(
                "Vous êtes intéressé(e) par la formule Premium.",
                [
                  "Système de gestion de contenu complet (CMS) avec accès à des fonctionnalités personnalisées.",
                  "Fonctionnalités avancées : réservation en ligne, espace client sécurisé, intégration d'un CRM.",
                  "Intégration API (connexion avec des services externes, synchronisation automatique de données).",
                  "UX/UI design optimisé pour maximiser la conversion des visiteurs en clients.",
                ]
              )
            }
          >
            COMMENCER
          </Button>
          <p>
            <strong>Design et conception :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Refonte
            personnalisée selon l’identité visuelle de l’entreprise.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Charte
            graphique simplifiée avec ajustement des couleurs actuelles.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Amélioration
            de l’interface utilisateur pour une meilleure expérience (UI/UX).
          </p>
          <p>
            <strong>Fonctionnalités :</strong>
          </p>
         
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Optimisation
            SEO avancée (structure des pages, optimisation des images,
            amélioration des mots-clés).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Intégration
            de nouveaux formulaires interactifs (demande de devis, sondages).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Liens directs
            avec les réseaux sociaux.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Adaptation du
            site existant pour qu’il soit 100 % responsive (compatibilité mobile
            et tablette).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Mise à jour
            du CMS pour faciliter la gestion du contenu.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Intégration
            d’outils de suivi des performances (Google Analytics).
          </p>
          <p>
            <strong>Maintenance :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Support
            technique pour corrections de bugs et ajustements mineurs (3 mois).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Hébergement
            et nom de domaine à votre charge, environ 4.20 € HT/Mois.
          </p>
        </Col>
      </Row>

 {/********************************************************** COMMENT CA MARCHE ************************************************************/} 

      <Row className="d-flex align-items-center fs-5">
        <Col md={6} className="my-5" data-aos="flip-down">
          <p>Commençons votre projet!</p>
          <h2>Comment ca marche?</h2>
          <img
            src="/assets/images/tarif.jpg"
            alt="Création de site internet"
            className="img-fluid"
          />
        </Col>
        <Col md={6} data-aos="zoom-in">
          <h3>Remplissez le questionnaire en 3 étapes</h3>
          <p>
          Pour bien démarrer notre collaboration et s'assurer que votre site
            reflète parfaitement vos attentes, je vous invite à prendre 1mn pour
            remplir le questionnaire correspondant à la formule que vous avez
            choisie en cliquant plus haut sur le boutton "COMMENCER".
          </p>
          <p>
            Ce document nous permettra de clarifier vos besoins en termes de
            design, de fonctionnalités, et d'objectifs.
          </p>
          <p>
          À la suite de ce questionnaire, je vous contacte par mail ou
            téléphone pour personnaliser, optimiser votre projet et ajuster le
            prix si nécessaire.
          </p>
          <p>
            Le paiement s'effectuera en deux temps : 30 % à la signature du
            devis, et le solde à la livraison finale du site.
          </p>
          <p>
            Vous bénéficierez également d’un suivi tout au long du processus de
            création, afin de vous tenir informé des avancées et de vous
            garantir une totale satisfaction.
          </p>
        </Col>
      </Row>
      {/* Fenêtre modale pour le formulaire */}
      <DevisModalRefonte
        show={showModal}
        handleClose={handleClose}
        {...modalProps}
      />
    </Container>
  );
}

export default TarifsRefonte;

{
  /* <Col md={4} className="my-5">
          <h3>Premium Refonte</h3>
          <p>À partir 2000 € HT </p>
          <p>
            Conçu pour les entreprises recherchant une refonte complète avec des
            fonctionnalités sur mesure et un design haut de gamme.
          </p>
          <Button variant="primary">COMMENCER</Button>

          <p>
            <strong>Design et conception :</strong>
          </p>
          <p>
            ✓ Refonte intégrale avec un design sur mesure et
            illustrations/animations personnalisées.
          </p>
          <p>
            ✓ Optimisation UX/UI pour maximiser la conversion des visiteurs en
            clients.
          </p>

          <p>
            <strong>Fonctionnalités :</strong>
          </p>
          <p>
            ✓ Système de gestion de contenu (CMS) avec fonctionnalités avancées
            sur mesure.
          </p>
          <p>
            ✓ Optimisation SEO premium (audit, stratégie de contenu, backlinks).
          </p>
          <p>
            ✓ Optimisation des performances (vitesse de chargement, hébergement
            dédié, CDN).
          </p>
          <p>
            ✓ Ajout de fonctionnalités avancées : espace client sécurisé,
            intégration CRM, boutique en ligne (e-commerce).
          </p>
          <p>✓ Intégration d’API pour connexion avec des services externes.</p>

          <p>
            <strong>Maintenance :</strong>
          </p>
          <p>
            ✓ Support technique pour corrections de bugs, ajouts de
            fonctionnalités, et ajustements (6 mois).
          </p>
          <p>✓ Hébergement premium avec garantie de disponibilité (SLA).</p>
          <p>
            ✓ Mise à jour régulière des fonctionnalités et optimisation continue
            du site.
          </p>
        </Col> */
}
