import {useState} from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../styles/Navbar.css"

function NavigationBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  let timeoutId = null;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setDropdownOpen(false);
    }, 200); // Délaiavant de fermer le menu
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect className="fixed-top border-bottom border-3 border-warning">
      <Container>
        {/* Marque avec espace à droite */}
        <Navbar.Brand as={Link} to="/">
          Marc SKALSKI Digital Freelance
        </Navbar.Brand>

        {/* Bouton hamburger pour mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Menu de navigation */}
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Espacement automatique à gauche du menu */}
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="nav-link me-4 nav-link-hover">
              Accueil
            </Nav.Link>

            {/* Sous-menu pour Tarifs */}
            <NavDropdown
              title="Tarifs"
              id="tarifs-dropdown"
              className="me-4 nav-link-hover"
              show={dropdownOpen}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >

              <NavDropdown.Item as={Link} to="/tarifs/creation-de-site">
                Création de site 
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tarifs/refonte-de-site">
                Refonte de site
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/galerie" className="me-4 nav-link-hover">
              Galerie
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="me-4 nav-link-hover">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
