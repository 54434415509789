import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import DevisModal from "../components/DevisModal";
import "../styles/Creation-Refonte.css";
import AOS from "aos";
import "aos/dist/aos.css";

function TarifsCreation() {
  const { hash } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const handleClose = () => setShowModal(false);

  // Fonction pour configurer et ouvrir le modal
  const handleShowModal = (title, suggestions) => {
    setModalProps({ title, suggestions }); // Passer le titre et les suggestions
    setShowModal(true); // Afficher le modal
  };

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Container className="my-5">
      <Row>
        <h1 className="text-center mt-5">Tarifs création de site</h1>
        <Col md={6} className="my-5 border-end">
          <h3 className="mb-5">Essentiel</h3>
          <p>
            À partir <strong>1200 € HT</strong>
          </p>
          <p>
            Idéal pour les petites entreprises ou les startups qui ont besoin
            d'un site vitrine simple et fonctionnel.
          </p>

          <Button
            variant="warning"
            className="w-100 mb-4 fw-bold"
            onClick={() =>
              handleShowModal(
                "Vous êtes intéressé(e) par la formule Essentiel.",
                [
                  "Optimisation SEO avancée",
                  "Système de gestion de contenu (CMS)",
                  "Formulaires interactifs (enquête, demande de devis)",
                  "Module d'analyse de trafic (Google Analytics)",
                  "UX/UI design optimisé pour maximiser la conversion des visiteurs en clients",
                ]
              )
            }
          >
            COMMENCER
          </Button>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Jusqu'à 5
            pages (Accueil, À propos, Services, Contact, etc.).
          </p>
          <p>
            <strong>Design et conception :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Création d'un
            design à partir d'un modèle préconçu avec personnalisation.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Charte
            graphique complète avec des couleurs prédéfinies.
          </p>
          <p>
            <strong>Fonctionnalités :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Optimisation
            SEO (titres, descriptions, URL propres).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Formulaire de
            contact (envoi par email).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Intégration
            des réseaux sociaux (liens directs).
          </p>

          <p>
            {" "}
            <span className="text-success fw-bolder fs-3">✓</span> Site
            responsive (adapté aux mobiles et tablettes).
          </p>
          <p>
            <strong>Maintenance :</strong>
          </p>
          <p>
            {" "}
            <span className="text-success fw-bolder fs-3">✓</span> Support
            technique pour corrections de bugs (1 mois).
          </p>

          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Hébergement
            et nom de domaine à votre charge, environ 4.20 € HT/Mois.
          </p>
        </Col>

        {/********************************************************** PREMIUM ************************************************************/}

        <Col md={6} className="my-5">
          <h3 className="mb-5">Premium</h3>
          <p>
            À partir <strong>2500 € HT</strong>
          </p>

          <p>
            Pour les entreprises qui souhaitent un site plus complet avec des
            fonctionnalités supplémentaires et un design avancé.
          </p>

          <Button
            variant="warning"
            className="w-100 mb-4 fw-bold"
            onClick={() =>
              handleShowModal(
                "Vous êtes intéressé(e) par la formule Premium.",
                [
                  "Système de gestion de contenu complet (CMS) avec accès à des fonctionnalités personnalisées.",
                  "Fonctionnalités avancées : réservation en ligne, espace client sécurisé, intégration d'un CRM.",
                  "Intégration API (connexion avec des services externes, synchronisation automatique de données).",
                  "UX/UI design optimisé pour maximiser la conversion des visiteurs en clients.",
                ]
              )
            }
          >
            COMMENCER
          </Button>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Jusqu'à 10
            pages (incluant une page Blog ou Actualités).{" "}
          </p>

          <p>
            <strong>Design et conception :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Design
            personnalisé en fonction de l'identité visuelle de l'entreprise.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Charte
            graphique complète avec des couleurs prédéfinies.
          </p>
          <p></p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Interface
            utilisateur optimisée pour une meilleure expérience client (UI/UX).
          </p>
          <p>
            <strong>Fonctionnalités :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Optimisation
            SEO avancé (mots-clés, structure de contenu, optimisation des
            images).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Formulaires
            interactifs (enquête, demande de devis).{" "}
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Intégration
            des réseaux sociaux (liens directs).
          </p>
          <p>
            {" "}
            <span className="text-success fw-bolder fs-3">✓</span> Site
            responsive (adapté aux mobiles et tablettes).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Système de
            gestion de contenu (CMS) pour permettre au client de modifier le
            contenu facilement.
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Module
            d'analyse de trafic (Google Analytics).
          </p>
          <p>
            <strong>Maintenance :</strong>
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Support
            technique pour corrections de bugs et petites modifications (3
            mois).
          </p>
          <p>
            <span className="text-success fw-bolder fs-3">✓</span> Hébergement
            et nom de domaine à votre charge, environ 4.20 € HT/Mois.
          </p>
        </Col>
      </Row>

      {/********************************************************** COMMENT CA MARCHE? ************************************************************/}

      <Row className="d-flex align-items-center fs-5">
        <Col md={6} data-aos="flip-down" className="my-5">
          <p>Commençons votre projet!</p>
          <h2>Comment ca marche?</h2>
          <img
            src="/assets/images/tarif.jpg"
            alt="Commençons votre projet de création de site internet"
            className="img-fluid"
          />
        </Col>
        <Col md={6} data-aos="zoom-in">
          <h3>Remplissez le questionnaire en 3 étapes</h3>
          <p>
            Pour bien démarrer notre collaboration et s'assurer que votre site
            reflète parfaitement vos attentes, je vous invite à prendre 1mn pour
            remplir le questionnaire correspondant à la formule que vous avez
            choisie en cliquant plus haut sur le boutton "COMMENCER".
          </p>
          <p>
            Ce document nous permettra de clarifier vos besoins en termes de
            design, de fonctionnalités, et d'objectifs.
          </p>
          <p>
            À la suite de ce questionnaire, je vous contacte par mail ou
            téléphone pour personnaliser, optimiser votre projet et ajuster le
            prix si nécessaire.
          </p>
          <p>
            Le paiement s'effectuera en deux temps : 30 % à la signature du
            devis, et le solde à la livraison finale du site.
          </p>
          <p>
            Vous bénéficierez également d’un suivi tout au long du processus de
            création, afin de vous tenir informé des avancées et de vous
            garantir une totale satisfaction.
          </p>
        </Col>
      </Row>
      {/* Fenêtre modale pour le formulaire */}
      <DevisModal show={showModal} handleClose={handleClose} {...modalProps} />
    </Container>
  );
}
export default TarifsCreation;

// {
//   <Col md={4} className="my-5">
//           <h3>Premium</h3>
//           <p>À partir 4000 € HT </p>
//           <p>
//             Pour les entreprises cherchant un site vitrine haut de gamme avec
//             des fonctionnalités sur mesure.
//           </p>
//           <Button
//             variant="primary"
//             onClick={() =>
//               handleShowModal(
//                 "Vous êtes intéressé(e) par la formule Premium.",
//                 [
//                   "Achat d'images",
//                   "Intégration d'une boutique en ligne (option e-commerce avec système de paiement sécurisé).",
//                   "Gestion des stocks: Suivi en temps réel des stocks disponibles.",
//                   "Forum de discussion: Créer une communauté autour de vos produits ou services.",
//                 ]
//               )
//             }
//           >
//             COMMENCER
//           </Button>
//           <p>
//             ✓ Nombre illimité de pages (avec possibilité d’ajouter de nouvelles
//             sections à la demande).
//           </p>

//           <p>
//             <strong>Design et conception :</strong>
//           </p>
//           <p>
//             ✓ Design 100 % sur mesure, création d'illustrations et animations
//             personnalisées.
//           </p>
//           <p>
//             ✓ UX/UI design optimisé pour maximiser la conversion des visiteurs
//             en clients.
//           </p>

//           <p>
//             <strong>Fonctionnalités :</strong>
//           </p>

//           <p>
//             ✓ Fonctionnalités avancées : réservation en ligne, espace client
//             sécurisé, intégration d'un CRM.
//           </p>
//           <p>
//             ✓ Proposez aux utilisateurs de s'inscrire à la newsletter lors de la
//             création de leur compte.
//           </p>
//           <p>
//             ✓ Système de gestion de contenu complet (CMS) avec accès à des
//             fonctionnalités personnalisées.
//           </p>
//           <p>
//             ✓ Optimisation SEO premium (audit complet, stratégie de contenu,
//             backlinks, etc.).
//           </p>
//           <p>
//             ✓ Optimisation des performances (temps de chargement, hébergement
//             dédié, CDN).
//           </p>
//           <p>
//             ✓ Intégration API (connexion avec des services externes,
//             synchronisation automatique de données).
//           </p>

//           <p>
//             <strong>Maintenance :</strong>
//           </p>
//           <p>
//             ✓ Support technique pour corrections de bugs, ajouts de
//             fonctionnalités et modifications (6 mois).
//           </p>
//           <p>✓ Hébergement premium avec garantie de disponibilité (SLA).</p>
//           <p>✓ Mise à jour régulière du site et de ses fonctionnalités.</p>
//         </Col>
// }
