import React from 'react';
import { Helmet } from 'react-helmet';

function SEO() {
  return (
    <Helmet>
      {/* Titre de la page, contenant des mots-clés pertinents */}
      <title>Création de Sites Web | Développeur Freelance - Marc SKALSKI</title>

      {/* Meta description optimisée */}
      <meta 
        name="description" 
        content="Développeur freelance spécialisé dans la création et la refonte de sites internet. Basé à Alès, je propose mes services à distance dans toute la France. Découvrez des solutions sur-mesure, du SEO à la maintenance." 
      />

      {/* Meta keywords améliorés */}
      <meta 
        name="keywords" 
        content="création de sites internet, développeur freelance, Alès, refonte site web, optimisation SEO, freelance France, développement web, site vitrine" 
      />

      {/* Meta pour le responsive design */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Meta pour le SEO local */}
      <meta name="geo.region" content="FR" />
      <meta name="geo.placename" content="Alès" />
      <meta name="geo.position" content="44.1234;4.0786" />
      <meta name="ICBM" content="44.1234, 4.0786" />

      {/* Open Graph (pour les réseaux sociaux comme Facebook) */}
      <meta property="og:title" content="Création de Sites Web à Alès | Développeur Freelance" />
      <meta property="og:description" content="Je suis développeur freelance basé à Alès. J'offre des services de création et refonte de sites web, optimisés pour le SEO, et disponibles dans toute la France." />
      <meta property="og:image" content="URL_de_ton_image_de_promotion" />
      <meta property="og:url" content="https://marcskalski.fr" />
      <meta property="og:type" content="website" />

      {/* Twitter Cards (pour optimiser l'affichage sur Twitter) */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Création de Sites Web à Alès | Développeur Freelance" />
      <meta name="twitter:description" content="Développeur freelance spécialisé dans la création et la refonte de sites internet. Basé à Alès, je travaille à distance dans toute la France." />
      <meta name="twitter:image" content="URL_de_ton_image_de_promotion" />

      {/* Canonical link */}
      <link rel="canonical" href="https://tonsite.com" />
    </Helmet>
  );
}

export default SEO;
