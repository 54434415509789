import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import emailjs from "emailjs-com";


function DevisModalRefonte({ show, handleClose, title, suggestions = [] }) {
  const [step, setStep] = useState(1);
  const [features, setFeatures] = useState([]);
  // const [file, setFile] = useState(null);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [customProjet, setCustomProjet] = useState("");
  const [customFeature, setCustomFeature] = useState("");
  const [customPersonal, setCustomPersonal] = useState("");
  const [status, setStatus] = useState(null);

  // const fileInputRef = React.useRef(null);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // const handleFileChange = (e) => setFile(e.target.files[0]);
  // const handleRemoveFile = () => {
  //   setFile(null); // Réinitialise l'état du fichier
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };

  const handleCloseModal = () => {
    // Réinitialiser tous les champs à leur état initial
    setStep(1);
    setFeatures([]);
    // setFile(null);
    setLastName("");
    setEmail("");
    setPhone("");
    setCustomFeature("");
    setCustomProjet("");
    setCustomPersonal("");

    handleClose(); // Appelle la fonction de fermeture fournie en prop
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construire les données du formulaire
    const templateParams = {
      name: lastName,
      email: email,
      phone: phone,
      customProjet: customProjet, 
      features: features.join(", "),
      customFeature: customFeature,
      customPersonal: customPersonal,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Remplacez par votre Service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_MODAL_ID, // Remplacez par votre Template ID
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID // Remplacez par votre User ID
      )
      .then(
        (response) => {
          setStatus({
            type: "success",
            message: "Votre demande de devis a été envoyée avec succès !",
          });
          setTimeout(() => {
            handleCloseModal();
            setStatus(null);
          }, 5000);
        },
        (error) => {
          console.error(error);
          setStatus({
            type: "error",
            message: "Une erreur est survenue. Veuillez réessayer plus tard.",
          });
          setTimeout(() => setStatus(null), 5000);
        }
      );

    e.target.reset();
  };

  const handleFeatureChange = (e) => {
    const selectedFeature = e.target.value;
    if (features.includes(selectedFeature)) {
      setFeatures(features.filter((feature) => feature !== selectedFeature));
    } else {
      setFeatures([...features, selectedFeature]);
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Étape 1 : Objectif du projet et téléchargement */}
          {step === 1 && (
            <div>
              <Form.Group className="mb-3" controlId="formProjectObjective">
                <Form.Label className="fw-bold">
                  Quel est l'objectif de votre projet ?
                </Form.Label>
                <p>
                  Décrire l'objectif principal du site web, par exemple : "Créer
                  un site web simple et fonctionnel pour un restaurant, mettant
                  en avant l'entreprise, ses services et facilitant la prise de
                  contact et la réservation avec les visiteurs."
                </p>
                <p>
                  Soyez bref et utilisez votre vocabulaire, ceci est seulement
                  une première approche qui va me permettre de mieux comprendre
                  vos attentes.
                </p>
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder="Décrire l'objectif de votre projet"
                  value={customProjet}
                  onChange={(e) => setCustomProjet(e.target.value)}
                />
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>
                  <strong>Ajoutez un fichier optionnel</strong> (exemple: Cahier
                  des charges ou brief) :
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                {file && (
                  <div className="mt-2">
                    <span>{file.name}</span>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={handleRemoveFile}
                      className="ms-2"
                    >
                      Supprimer
                    </Button>
                  </div>
                )}
              </Form.Group> */}

              <Button onClick={nextStep}>Suivant</Button>
            </div>
          )}

          {/* Étape 2 : Fonctionnalités supplémentaires */}
          {step === 2 && (
            <div>
              <Form.Group className="mb-3" controlId="formFeatures">
                <Form.Label className="fw-bold">
                  Fonctionnalités supplémentaires souhaitée ?
                </Form.Label>
                <p>
                  Nous pouvons personnaliser votre projet en y intégrant de
                  nombreuses fonctionnalités. Voici quelques exemples pour vous
                  inspirer.
                </p>
                {suggestions.map((suggestion, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={suggestion}
                    value={suggestion}
                    checked={features.includes(suggestion)}
                    onChange={handleFeatureChange}
                  />
                ))}

                {/* Autres fonctionnalités */}
                <Form.Group className="mt-3" controlId="formCustomFeature">
                  <Form.Label className="fw-bold">
                    Autre fonctionnalité personnalisée souhaitée?
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Décrivez la ou les fonctionnalités souhaitée"
                    value={customFeature}
                    onChange={(e) => setCustomFeature(e.target.value)}
                  />
                </Form.Group>
              </Form.Group>

              <Button onClick={prevStep} className="me-2">
                Retour
              </Button>
              <Button onClick={nextStep}>Suivant</Button>
            </div>
          )}

          {/* Étape 3 : Informations personnelles */}
          {step === 3 && (
            <div>
             <Form.Label className="fw-bold">
                Nom*, Prénom* et Raison sociale
              </Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Votre Nom, Prénom et Raison Sociale"
                    name="name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label className="fw-bold mt-2">Email*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label className="fw-bold">Téléphone*</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Entrez votre numéro de téléphone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formMessagePersonnel">
                <Form.Label className="fw-bold">Message personnel</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Prise de RDV par téléphone à une heure précise, idée personnelle..."
                  value={customPersonal}
                  onChange={(e) => setCustomPersonal(e.target.value)}
                />
              </Form.Group>

              <Button onClick={prevStep} className="me-2">
                Retour
              </Button>

              {/* Champs cachés pour envoyer les données des étapes précédentes */}
              <Form.Control
                type="hidden"
                name="customProjet"
                value={customProjet}
              />
              <Form.Control
                type="hidden"
                name="features"
                value={features.join(", ")}
              />
              <Form.Control
                type="hidden"
                name="customFeature"
                value={customFeature}
              />
              <Button type="submit">Envoyer</Button>
            </div>
          )}
        </Form>

        {status && (
          <Alert
            variant={status.type === "success" ? "success" : "danger"}
            className="mt-3"  style={{ width: "max-content"}}
          >
            {status.message}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DevisModalRefonte;
